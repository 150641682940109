import axios from 'axios';

const token = localStorage.getItem('userToken');
const removeUserFromLocalStorage = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userLastName');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userId');
    localStorage.removeItem('country');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userCurrency');
    localStorage.removeItem('photo');
    localStorage.removeItem('company');
    localStorage.removeItem('channelpartnerId');
    window.location.href = '/login';
};

let rootURL;
// data

if (process.env.REACT_APP_NODE_ENV === 'production') {
    rootURL = 'https://api.garagemobility.com/api/';
} else {
    rootURL = 'http://localhost:3000/api';
}

const authFetch = axios.create({
    baseURL: rootURL
});

authFetch.interceptors.request.use(
    (config) => {
        config.headers.common['auth-token'] = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

authFetch.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log('RESPONSE ERROR', error.response);
        if (error.response.status === 401) {
            removeUserFromLocalStorage();
            console.log('Authentication Error', error.response.data.message);
        }
        return Promise.reject(error);
    }
);

export { rootURL, authFetch, removeUserFromLocalStorage };
