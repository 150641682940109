const ApiUrl = {
    BaseUrlInfo: 'http://localhost:3001',
    BaseUrl: 'https://api.garagemobility.com/api',
    MiraUrl: 'https://mira.garagemobility.com',
    MiraBaseUrl: 'https://mira.garagemobility.com',
    MiraStagingBaseUrl: 'https://mira.garagemobility.com/api/v1/whatsapp',
    MiraStagingSocketUrl: 'https://mira.garagemobility.com/',
    LoginUrl: 'https://api.garagemobility.com/api/user/login',
    UserProfile: 'https://api.garagemobility.com/api/user/myprofile',
    ForgetPasswordUrl: 'https://api.garagemobility.com/api/user/passwordreset',
    NewPasswordUrl: 'https://api.garagemobility.com/api/user/newpassword',
    OtpPinUrl: 'https://api.garagemobility.com/api/user/otpverification',
    NewEmailVerificationUrl: 'https://api.garagemobility.com/api/user/newemailverification',
    AllUsersUrl: 'https://api.garagemobility.com/api/user/users',
    AddUserUrl: 'https://api.garagemobility.com/api/user/register',
    ShowUserUrl: 'https://api.garagemobility.com/api/user/show/',
    UploadPhotoUrl: 'https://api.garagemobility.com/api/user/uploadphoto',
    UpdateProfileUrl: 'https://api.garagemobility.com/api/user/profileupdate',
    UpdateUserUrl: 'https://api.garagemobility.com/api/user/update',
    DeleteUserUrl: 'https://api.garagemobility.com/api/user/remove',
    AllRolesUrl: 'https://api.garagemobility.com/api/role/roles',
    AllChannelUrl: 'https://api.garagemobility.com/api/channel/channels',
    AddChannelUrl: 'https://api.garagemobility.com/api/channel/add',
    UpdateChannelUrl: 'https://api.garagemobility.com/api/channel/update',
    ShowChannelUrl: 'https://api.garagemobility.com/api/channel/show/',
    DeleteChannelUrl: 'https://api.garagemobility.com/api/channel/remove',
    AllOrderStatusUrl: 'https://api.garagemobility.com/api/orderstatus/orderstatus',
    AddOrderStatusUrl: 'https://api.garagemobility.com/api/orderstatus/add',
    ShowOrderStatusUrl: 'https://api.garagemobility.com/api/orderstatus/show/',
    UpdateOrderStatusUrl: 'https://api.garagemobility.com/api/orderstatus/update',
    DeleteOrderStatusUrl: 'https://api.garagemobility.com/api/orderstatus/remove',
    AllPaymentStatusUrl: 'https://api.garagemobility.com/api/paymentstatus/paymentstatus',
    AddPaymentStatusUrl: 'https://api.garagemobility.com/api/paymentstatus/add',
    UpdatePaymentStatusUrl: 'https://api.garagemobility.com/api/paymentstatus/update',
    ShowPaymentStatusUrl: 'https://api.garagemobility.com/api/paymentstatus/show/',
    DeletePaymentStatusUrl: 'https://api.garagemobility.com/api/paymentstatus/remove',
    CreateRoleUrl: 'https://api.garagemobility.com/api/role/add',
    ViewRoleUrl: 'https://api.garagemobility.com/api/role/show/',
    UpdateRoleUrl: 'https://api.garagemobility.com/api/role/update',
    DeleteRoleUrl: 'https://api.garagemobility.com/api/role/remove',
    AllCarrierUrl: 'https://api.garagemobility.com/api/carrier/carriers',
    AddCarrierUrl: 'https://api.garagemobility.com/api/carrier/add',
    ViewCarrierUrl: 'https://api.garagemobility.com/api/carrier/show/',
    UpdateCarrierUrl: 'https://api.garagemobility.com/api/carrier/update',
    DeleteCarrierUrl: 'https://api.garagemobility.com/api/carrier/remove',
    ActiveCarrierUrl: 'https://api.garagemobility.com/api/carrier/carriesactive',
    ActiveCarrierUrlNG: 'https://api.garagemobility.com/api/carrier/carriesactiveng',
    PartListUrl: 'https://api.garagemobility.com/api/part/parts',
    PartMainUrlList: 'https://api.garagemobility.com/api/part/mainparts',
    ImsStatusUrlList: 'https://api.garagemobility.com/api/part/partimsstatus',
    UnApprovedPartListUrl: 'https://api.garagemobility.com/api/part/unapproveparts',
    ActivePartUrl: 'https://api.garagemobility.com/api/part/partsactive',
    DistributorPartUrl: 'https://api.garagemobility.com/api/part/distributorpart',
    AddPartsUrl: 'https://api.garagemobility.com/api/part/add',
    AddPartSourcedUrl: 'https://api.garagemobility.com/api/part/addpartsourced',
    NewRetailerInfoUrl: 'https://api.garagemobility.com/api/newordersretailer/newretailerinfo',
    FilterNewRetailerUrl: 'https://api.garagemobility.com/api/newordersretailer/filternewretailerinfodate',
    UploadPartsUrl: 'https://api.garagemobility.com/api/part/upload',
    FilterPartStatusUrl: 'https://api.garagemobility.com/api/part/filterpartstatus',
    ShowPartsUrl: 'https://api.garagemobility.com/api/part/show/',
    FilterOrderPartsUrl: 'https://api.garagemobility.com/api/part/filterorderpartsshow',
    UpdatePartsUrl: 'https://api.garagemobility.com/api/part/update',
    OrderPartsShowUrl: 'https://api.garagemobility.com/api/part/orderpartsshow/',
    UpdateProductImageUrl: 'https://api.garagemobility.com/api/part/updateImageProduct',
    DeletePartsUrl: 'https://api.garagemobility.com/api/part/remove',
    DistributorUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnerdistributor',
    AddDistributorUrl: 'https://api.garagemobility.com/api/channelpartner/adddistributor',
    AddRetailerUrl: 'https://api.garagemobility.com/api/channelpartner/addretail',
    AddMechanicUrl: 'https://api.garagemobility.com/api/channelpartner/addmechanic',
    UploadIDCardUrl: 'https://api.garagemobility.com/api/channelpartner/uploadidcard',
    UploadIDCardPartsUrl: 'https://api.garagemobility.com/api/channelpartner/uploadpartsimage',
    UploadMechanicUrl: 'https://api.garagemobility.com/api/channelpartner/uploadMechanic',
    AllRetailerUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnerretail',
    AllMainRetailerUrl: 'https://api.garagemobility.com/api/channelpartner/mainchannelretailerpartner',
    RetailerMessageUrl: 'https://api.garagemobility.com/api/channelpartner/retailerMessage',
    AllRetailerUrlDashboard: 'https://api.garagemobility.com/api/channelpartner/channelspartnerretaildashboard',
    AllActiveRetailUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnerretailactive',
    UploadRetailsUrl: 'https://api.garagemobility.com/api/channelpartner/uploadRetails',
    AllMechanicUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnermechanic',
    AllActiveMechanicUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnermechanicactive',
    UpdateChannelPartnerURL: 'https://api.garagemobility.com/api/channelpartner/update',
    UpdateChannelPartnerURLNG: 'https://api.garagemobility.com/api/channelpartnerng/updateng',
    ViewAllChannelPartnerURL: 'https://api.garagemobility.com/api/channelpartner/show/',
    AddShopChannelPartnerUrl: 'https://api.garagemobility.com/api/channelpartner/addshop',
    AddOrderTagsUrl: 'https://api.garagemobility.com/api/order/addordertags',
    DeleteChannelPartnerUrl: 'https://api.garagemobility.com/api/channelpartner/remove',
    AllStageUrl: 'https://api.garagemobility.com/api/stage/stages',
    AddStageUrl: 'https://api.garagemobility.com/api/stage/add',
    ViewStageUrl: 'https://api.garagemobility.com/api/stage/show/',
    UpdateStageUrl: 'https://api.garagemobility.com/api/stage/update',
    DeleteStageUrl: 'https://api.garagemobility.com/api/stage/remove',
    AllCssOrdersUrl: 'https://api.garagemobility.com/api/order/cssorders',
    AllProformaOrdersUrl: 'https://api.garagemobility.com/api/order/cssproformaorders',
    AllPreSoldOrdersUrl: 'https://api.garagemobility.com/api/order/csspresoldorders',
    DarkStoreOrdersUrl: 'https://api.garagemobility.com/api/order/cssdarkstoreorders',
    SalesDriveCssOrdersUrl: 'https://api.garagemobility.com/api/order/csssalesdriveorders',
    RetentionDriveCssOrdersUrl: 'https://api.garagemobility.com/api/order/retentiondriveorders',
    ConversionDriveCssOrdersUrl: 'https://api.garagemobility.com/api/order/conversiondriveorders',
    AllBatchTestUrl: 'https://api.garagemobility.com/api/orderpriority/priorityformdata',
    AllPdfBatchTestUrl: 'https://api.garagemobility.com/api/orderbatch/createbatchpdf',
    AllOrdersAdminUrl: 'https://api.garagemobility.com/api/order/orders',
    AddOrdersUrl: 'https://api.garagemobility.com/api/order/add',
    MyOrdersUrl: 'https://api.garagemobility.com/api/order/myorder',
    MyMainOrdersUrl: 'https://api.garagemobility.com/api/order/mainmyorder',
    UpdateOrdersUrl: 'https://api.garagemobility.com/api/order/update',
    UpdateDiscountUrl: 'https://api.garagemobility.com/api/order/discountupdate',
    UpdateCssOrderIssueUrl: 'https://api.garagemobility.com/api/order/updatecssissue',
    UpdateOrdersUrlNG: 'https://api.garagemobility.com/api/order/updateng',
    UpdateSalesOrdersUrl: 'https://api.garagemobility.com/api/order/updatesalesorder',
    UpdatePreSoldOrdersUrl: 'https://api.garagemobility.com/api/order/presoldUpdateOrder',
    UpdateCorrectOrderUrl: 'https://api.garagemobility.com/api/order/correctorderno',
    AllFulfilledSalesOrdersUrl: 'https://api.garagemobility.com/api/order/allordersbyday',
    FilterSalesOrdersFulfilledUrl: 'https://api.garagemobility.com/api/order/filterallsalesfulfilledorders',
    ViewOrdersUrl: 'https://api.garagemobility.com/api/order/show/',
    ViewPreSoldOrdersUrl: 'https://api.garagemobility.com/api/order/presoldview/',
    CheckRetailer: 'https://api.garagemobility.com/api/orderpriority/checklocation',
    DeleteOrdersUrl: 'https://api.garagemobility.com/api/order/remove',
    DownloadPdfOrdersUrl: 'https://api.garagemobility.com/api/order/createpdf',
    DownloadManuelLoyaltyDiscountOrdersUrl: 'https://api.garagemobility.com/api/order/manualapplyloyalty',
    DownloadPdfIssuesUrl: 'https://api.garagemobility.com/api/order/issuespdf',
    DownloadPdfOrdersUrlNG: 'https://api.garagemobility.com/api/order/createpdfng',
    DownloadPdfOrderPreviewsUrl: 'https://api.garagemobility.com/api/order/createpdfnew',
    DownloadPdfDarkStoreOrderPreviewsUrl: 'https://api.garagemobility.com/api/order/createpdfdarkstore',
    DownloadSalesDrivePdfPreviewUrl: 'https://api.garagemobility.com/api/order/createpdfsalesdrive',
    DownloadRetentionDrivePdfPreviewUrl: 'https://api.garagemobility.com/api/order/createpdfretentiondrive',
    FilterOrdersByDateUrl: 'https://api.garagemobility.com/api/order/filterorderbydate',
    FilterOrderDateAdminUrl: 'https://api.garagemobility.com/api/order/filterdateorderadmin',
    FilterOrderByRMUrl: 'https://api.garagemobility.com/api/order/filterorderbyrm',
    FilterProformaOrdersDateUrl: 'https://api.garagemobility.com/api/order/filtercssproformaorderbydate',
    FilterPresoldOrderDateUrl: 'https://api.garagemobility.com/api/order/filterpresoldorderbydate',
    FilterDarkStoreOrdersDateUrl: 'https://api.garagemobility.com/api/order/filtercssdarkstoreorderbydate',
    FilterSalesDriveOrderDateUrl: 'https://api.garagemobility.com/api/order/filtercsssalesdriveorderbydate',
    FilterRetentionDriveOrderDateUrl: 'https://api.garagemobility.com/api/order/filtercssretentiondriveorderbydate',

    FilterConversioinDriveOrderDateUrl: 'https://api.garagemobility.com/api/order/filtercssconversiondriveorderbydate',

    FilterOrdersBySalesAgentDateUrl: 'https://api.garagemobility.com/api/order/filtersalesorderbydate',
    FilterOrdersBySalesAgentDateUrlNG: 'https://api.garagemobility.com/api/order/filtersalesorderbydatenigeria',
    TransactionOrdersUrl: 'https://api.garagemobility.com/api/order/transactionorders',
    FilterOrderByOrderStatusUrl: 'https://api.garagemobility.com/api/order/filterorderbyorderstatus',
    FilterOrderByOrderCityUrl: 'https://api.garagemobility.com/api/order/filterorderbycity',
    FilterOrderByDarkStoreCityUrl: 'https://api.garagemobility.com/api/order/filtercssdarkstoreorderbycity',
    FilterSalesDriveOrderByCityUrl: 'https://api.garagemobility.com/api/order/filtercsssalesdriveorderbycity',
    FilterRetentionDriveOrderByCityUrl: 'https://api.garagemobility.com/api/order/filtercssretentionsdriveorderbycity',
    FilterConversionDriveOrderByCityUrl: 'https://api.garagemobility.com/api/order/filtercssconversiondriveorderbycity',
    FilterDepositStatusDataUrl: 'https://api.garagemobility.com/api/order/filterdepositestatusinfo',
    VinSearchUrl: 'https://api.garagemobility.com/api/service/vinsearch',
    FetchPdfLinkOrdersUrl: 'https://api.garagemobility.com/api/order/fetchpdf',
    AllOrderTypeUrl: 'https://api.garagemobility.com/api/ordertype/ordertypes',
    AddOrderTypeUrl: 'https://api.garagemobility.com/api/ordertype/add',
    ViewOrderTypeUrl: 'https://api.garagemobility.com/api/ordertype/show/',
    UpdateOrderTypeUrl: 'https://api.garagemobility.com/api/ordertype/update',
    DeleteOrderTypeUrl: 'https://api.garagemobility.com/api/ordertype/remove',
    AllActiveOrderTypes: 'https://api.garagemobility.com/api/ordertype/ordertypesactive',
    AllActivePaymentStatus: 'https://api.garagemobility.com/api/paymentstatus/paymentstatusactive',
    AllActiveOrderStatus: 'https://api.garagemobility.com/api/orderstatus/orderstatusactive',
    AllSalesOrderStatus: 'https://api.garagemobility.com/api/orderstatus/orderstatusactivesales',
    AllActiveChannel: 'https://api.garagemobility.com/api/channel/channelsactive',
    AllChannelPartnersOrders: 'https://api.garagemobility.com/api/channelpartner/channelpartner',
    AllActivePickDropType: 'https://api.garagemobility.com/api/pickdroptype/pickdropactive',
    AllPickdropUrl: 'https://api.garagemobility.com/api/pickdroptype/pickdroptype',
    AddPickDropUrl: 'https://api.garagemobility.com/api/pickdroptype/add',
    ViewPickDropUrl: 'https://api.garagemobility.com/api/pickdroptype/show/',
    UpdatePickDropUrl: 'https://api.garagemobility.com/api/pickdroptype/update',
    DeletePickDropUrl: 'https://api.garagemobility.com/api/pickdroptype/remove',
    SalesOrderRequest: 'https://api.garagemobility.com/api/order/orderRequest',
    CssOrderRequestUrl: 'https://api.garagemobility.com/api/order/cssorderrequest',
    SalesOrderRequestNg: 'https://api.garagemobility.com/api/order/orderRequestng',
    DeleteSalesOrderRequest: 'https://api.garagemobility.com/api/order/remove',
    UpdateDriverOrdersUrl: 'https://api.garagemobility.com/api/order/updatedrivercash',
    DeleteSalesOrderRequestNG: 'https://api.garagemobility.com/api/order/removeng',
    AllContactOrder: 'https://api.garagemobility.com/api/contactorder/contactorder',
    AddContactOrder: 'https://api.garagemobility.com/api/contactorder/add',
    ViewContactOrder: 'https://api.garagemobility.com/api/contactorder/show/',
    UpdateContactOrder: 'https://api.garagemobility.com/api/contactorder/update',
    DeleteContactOrder: 'https://api.garagemobility.com/api/contactorder/remove',
    UploadBulkVehicleUrl: 'https://api.garagemobility.com/api/car/fleetcarupload',
    UploadBulkVehicleFleetPartnerUrl: 'https://api.garagemobility.com/api/car/fleetpartnercarupload',
    BulkUploadListVehicleUrl: 'https://api.garagemobility.com/api/car/fleetcarsbulk',
    BulkUploadListFleetPartnerVehicleUrl: 'https://api.garagemobility.com/api/car/bulkfleetpartner/',
    AllCar: 'https://api.garagemobility.com/api/car/cars',
    AllFleetCar: 'https://api.garagemobility.com/api/car/fleetcars',
    AllFleetCarss: 'https://api.garagemobility.com/api/car/fleetcarscss',
    AllActiveCar: 'https://api.garagemobility.com/api/car/caractive',
    AddCar: 'https://api.garagemobility.com/api/car/add',
    AddFleetCar: 'https://api.garagemobility.com/api/car/fleetcaradd',
    ViewCar: 'https://api.garagemobility.com/api/car/show/',
    ViewFleetCar: 'https://api.garagemobility.com/api/car/fleetcarshow/',
    UpdateCar: 'https://api.garagemobility.com/api/car/update',
    UpdateFleetCar: 'https://api.garagemobility.com/api/car/updatefleetcar',
    DeleteCar: 'https://api.garagemobility.com/api/car/remove',
    DeleteFleetCar: 'https://api.garagemobility.com/api/car/fleetcarremove',
    AllCarModel: 'https://api.garagemobility.com/api/carmodel/carmodels',
    AllCarModelFleet: 'https://api.garagemobility.com/api/carmodel/fleetcarmodels',
    AllCarModelActive: 'https://api.garagemobility.com/api/carmodel/carmodelactive',
    AllCarModelActiveFleet: 'https://api.garagemobility.com/api/carmodel/fleetcarmodelactive',
    AddCarModel: 'https://api.garagemobility.com/api/carmodel/add',
    AddCarModelFleet: 'https://api.garagemobility.com/api/carmodel/fleetmodeladd',
    EditCarModel: 'https://api.garagemobility.com/api/carmodel/show/',
    EditCarModelFleet: 'https://api.garagemobility.com/api/carmodel/fleetmodelshow/',
    UpdateCarModel: 'https://api.garagemobility.com/api/carmodel/update',
    UpdateCarModelFleet: 'https://api.garagemobility.com/api/carmodel/updatefleetmodel',
    DeleteCarModel: 'https://api.garagemobility.com/api/carmodel/remove',
    DeleteCarModelFleet: 'https://api.garagemobility.com/api/carmodel/fleetmodelremove',
    AllPolicyId: 'https://api.garagemobility.com/api/policyid/policyid',
    AllActivePolicyId: 'https://api.garagemobility.com/api/policyid/policyidactive',
    AddPolicyId: 'https://api.garagemobility.com/api/policyid/add',
    EditPolicyId: 'https://api.garagemobility.com/api/policyid/show/',
    UpdatePolicyId: 'https://api.garagemobility.com/api/policyid/update',
    DeletePolicyId: 'https://api.garagemobility.com/api/policyid/remove',
    AllServiceFee: 'https://api.garagemobility.com/api/insurerservicefee/insurerservicefees',
    AllServiceFeeActive: 'https://api.garagemobility.com/api/insurerservicefee/insurerservicefeeactive',
    AddServiceFee: 'https://api.garagemobility.com/api/insurerservicefee/add',
    EditServiceFee: 'https://api.garagemobility.com/api/insurerservicefee/show/',
    UpdateServiceFee: 'https://api.garagemobility.com/api/insurerservicefee/update',
    DeleteServiceFee: 'https://api.garagemobility.com/api/insurerservicefee/remove',
    AllInsurerOrderPart: 'https://api.garagemobility.com/api/insurerorderpart/insurerorderparts',
    AddInsurerOrderPart: 'https://api.garagemobility.com/api/insurerorderpart/add',
    EditInsurerOrderPart: 'https://api.garagemobility.com/api/insurerorderpart/show/',
    UpdateInsurerOrderPart: 'https://api.garagemobility.com/api/insurerorderpart/update',
    DownloadInsurerOrderPart: 'https://api.garagemobility.com/api/insurerorderpart/createorderpdf',
    DeleteInsurerOrderPart: 'https://api.garagemobility.com/api/insurerorderpart/remove',
    AllRequestService: 'https://api.garagemobility.com/api/requestservice/requestservices',
    AddRequestService: 'https://api.garagemobility.com/api/requestservice/add',
    EditRequestService: 'https://api.garagemobility.com/api/requestservice/show/',
    UpdateRequestService: 'https://api.garagemobility.com/api/requestservice/update',
    DeleteRequestService: 'https://api.garagemobility.com/api/requestservice/remove',
    AllService: 'https://api.garagemobility.com/api/service/service',
    AddService: 'https://api.garagemobility.com/api/service/add',
    EditService: 'https://api.garagemobility.com/api/service/show/',
    UpdateService: 'https://api.garagemobility.com/api/service/update',
    DeleteService: 'https://api.garagemobility.com/api/service/remove',
    AllActiveService: 'https://api.garagemobility.com/api/service/serviceactive',
    AllRequestDiagnostics: 'https://api.garagemobility.com/api/requestdiagnostics/requestdiagnostics',
    AddRequestDiagnostics: 'https://api.garagemobility.com/api/requestdiagnostics/add',
    ViewRequestDiagnostics: 'https://api.garagemobility.com/api/requestdiagnostics/show/',
    UpdateRequestDiagnostics: 'https://api.garagemobility.com/api/requestdiagnostics/update',
    DeleteRequestDiagnostics: 'https://api.garagemobility.com/api/requestdiagnostics/remove',
    AllClaimId: 'https://api.garagemobility.com/api/claimid/claimid',
    AddClaimId: 'https://api.garagemobility.com/api/claimid/add',
    ViewClaimId: 'https://api.garagemobility.com/api/claimid/show/',
    UpdateClaimId: 'https://api.garagemobility.com/api/claimid/update',
    DeleteClaimId: 'https://api.garagemobility.com/api/claimid/remove',
    AllClaimType: 'https://api.garagemobility.com/api/claimtype/claimtype',
    AddClaimType: 'https://api.garagemobility.com/api/claimtype/add',
    ViewClaimType: 'https://api.garagemobility.com/api/claimtype/show/',
    UpdateClaimType: 'https://api.garagemobility.com/api/claimtype/update',
    DeleteClaimType: 'https://api.garagemobility.com/api/claimtype/remove',
    AllServiceProvider: 'https://api.garagemobility.com/api/serviceprovider/serviceprovider',
    AddServiceProvider: 'https://api.garagemobility.com/api/serviceprovider/add',
    ViewServiceProvider: 'https://api.garagemobility.com/api/serviceprovider/show/',
    UpdateServiceProvider: 'https://api.garagemobility.com/api/serviceprovider/update',
    DeleteServiceProvider: 'https://api.garagemobility.com/api/serviceprovider/remove',
    AllSalesTeam: 'https://api.garagemobility.com/api/salesteam/salesteam',
    AllActiveSalesTeam: 'https://api.garagemobility.com/api/salesteam/salesteamactive',
    TestBatchTest: 'https://api.garagemobility.com/api/orderpriority/add',
    AddSalesTeam: 'https://api.garagemobility.com/api/salesteam/add',
    ViewSalesTeam: 'https://api.garagemobility.com/api/salesteam/show/',
    UpdateSalesTeam: 'https://api.garagemobility.com/api/salesteam/update',
    DeleteSalesTeam: 'https://api.garagemobility.com/api/salesteam/remove',
    AllCustomerSuccessTeam: 'https://api.garagemobility.com/api/customersuccessteam/customersuccessteam',
    AllActiveCustomerSuccess: 'https://api.garagemobility.com/api/customersuccessteam/customersuccessteamactive',
    AddCustomerSuccessTeam: 'https://api.garagemobility.com/api/customersuccessteam/add',
    ViewCustomerSuccessTeam: 'https://api.garagemobility.com/api/customersuccessteam/show/',
    UpdateCustomerSuccessTeam: 'https://api.garagemobility.com/api/customersuccessteam/update',
    DeleteCustomerSuccessTeam: 'https://api.garagemobility.com/api/customersuccessteam/remove',
    SalesTeamActiveSalesPage: 'https://api.garagemobility.com/api/salesteam/salesteamactivesales',
    ChannelActiveSalesPage: 'https://api.garagemobility.com/api/channel/channelsactivesales',
    ChannelActiveWebPageUrl: 'https://api.garagemobility.com/api/channel/channelswebactivesales',
    AllChannelPartnersSales: 'https://api.garagemobility.com/api/channelpartner/channelpartnersales/',
    AllPartsActiveSales: 'https://api.garagemobility.com/api/part/partsactivesales',
    AllComparePrice: 'https://api.garagemobility.com/api/compareprice/compareprices',
    AddComparePrice: 'https://api.garagemobility.com/api/compareprice/add',
    ViewComparePrice: 'https://api.garagemobility.com/api/compareprice/show/',
    UpdateComparePrice: 'https://api.garagemobility.com/api/compareprice/update',
    RemoveComparePrice: 'https://api.garagemobility.com/api/compareprice/remove',
    AllFulfilmentOrders: 'https://api.garagemobility.com/api/order/fulfilmentorders',
    ViewFulfilmentOrder: 'https://api.garagemobility.com/api/order/fulfilmentshow/',
    UpdateFulfilmentOrder: 'https://api.garagemobility.com/api/order/fulfilmentupdate',
    AllFulfilmentPickDropType: 'https://api.garagemobility.com/api/pickdroptype/pickdropactivefulfillment',
    AllCarrierFulfillment: 'https://api.garagemobility.com/api/carrier/carriesactivefullfilment',
    AllChannelPartnerFulfillment: 'https://api.garagemobility.com/api/channelpartner/channelpartnerfulfillment',
    AllOrderStatusFulfillment: 'https://api.garagemobility.com/api/orderstatus/orderstatusfulfillment',
    TotalFulfilledOrders: 'https://api.garagemobility.com/api/order/totalfulfilledOrders',
    PriceChanges: 'https://api.garagemobility.com/api/part/partpricechanges/',
    TrackOrderPayment: 'https://api.garagemobility.com/api/order/orderpaymentstrack/',
    RetailerOrderHistoryUrl: 'https://api.garagemobility.com/api/order/retailerorderhistory/',
    RetailerDebitCreditHistoryUrl: 'https://api.garagemobility.com/api/order/retailerdebitcreditorderhistory/',
    RetailerFulfilledorderHistoryData: 'https://api.garagemobility.com/api/order/retailerorderfulfilledhistorydata/',
    AllSalesStageUrl: 'https://api.garagemobility.com/api/stage/stagessalesactive',
    AddChannelParnterSales: 'https://api.garagemobility.com/api/channelpartner/addpartnersales',
    FleetServiceUrl: 'https://api.garagemobility.com/api/fleetservice/fleetservice',
    AddFleetServiceUrl: 'https://api.garagemobility.com/api/fleetservice/add',
    ViewFleetServiceUrl: 'https://api.garagemobility.com/api/fleetservice/show/',
    UpdateFleetServiceUrl: 'https://api.garagemobility.com/api/fleetservice/update',
    RemoveFleetServiceUrl: 'https://api.garagemobility.com/api/fleetservice/remove',
    FleetServiceIdUrl: 'https://api.garagemobility.com/api/fleetserviceid/fleetserviceid',
    FleetActiveServiceIdUrl: 'https://api.garagemobility.com/api/fleetserviceid/fleetserviceidactive',
    AddFleetServiceIdUrl: 'https://api.garagemobility.com/api/fleetserviceid/add',
    ViewFleetServiceIdUrl: 'https://api.garagemobility.com/api/fleetserviceid/show/',
    UpdateFleetServiceIdUrl: 'https://api.garagemobility.com/api/fleetserviceid/update',
    RemoveFleetServiceIdUrl: 'https://api.garagemobility.com/api/fleetserviceid/remove',
    FleetServiceFeeUrl: 'https://api.garagemobility.com/api/fleetservicefee/fleetservicefees',
    FleetActiveServiceFeeUrl: 'https://api.garagemobility.com/api/fleetservicefee/fleetservicefeeactive',
    AddFleetServiceFeeUrl: 'https://api.garagemobility.com/api/fleetservicefee/add',
    ViewFleetServiceFeeUrl: 'https://api.garagemobility.com/api/fleetservicefee/show/',
    UpdateFleetServiceFeeUrl: 'https://api.garagemobility.com/api/fleetservicefee/update',
    RemoveFleetServiceFeeUrl: 'https://api.garagemobility.com/api/fleetservicefee/remove',
    FleetPartnerUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnerfleet',
    FleetPartnerIndividualUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnerfleetindividual',
    AddFleetPartnerUrl: 'https://api.garagemobility.com/api/channelpartner/addfleet',
    AddFleetCustomerUrl: 'https://api.garagemobility.com/api/channelpartner/addfleetcustomer',
    FleetOrderPartsUrl: 'https://api.garagemobility.com/api/fleetorderparts/fleetorderparts',
    MyChannelPartnerFleetOrderPartsUrl: 'https://api.garagemobility.com/api/fleetorderparts/mychannelpartnerfleetorderparts',
    AddFleetOrderPartsUrl: 'https://api.garagemobility.com/api/fleetorderparts/add',
    ViewFleetOrderPartsUrl: 'https://api.garagemobility.com/api/fleetorderparts/show/',
    UpdateFleetOrderPartsUrl: 'https://api.garagemobility.com/api/fleetorderparts/update',
    FleetOrderPartPdfDownloadUrl: 'https://api.garagemobility.com/api/fleetorderparts/createfleetorderpdf',
    FleetActivePartnerUrl: 'https://api.garagemobility.com/api/channelpartner/channelspartnerfleetactive',
    AllCarModelsDetailsUrl: 'https://api.garagemobility.com/api/carmodel/carmodelsdetails/',
    AllCarModelsDetailsUrlFleetModel: 'https://api.garagemobility.com/api/carmodel/carmodelsdetailsfleetmodel/',
    AllCarModelsDetailsUrlFleetModelss: 'https://api.garagemobility.com/api/carmodel/carmodelsdetailsfleetmodelcss/',
    GarageMainSignupUrl: 'https://api.garagemobility.com/api/authsignups/register',
    FleetRequestServiceUrl: 'https://api.garagemobility.com/api/fleetrequestservice/myfleetrequestservice',
    GarageFleetRequestServiceUrl: 'https://api.garagemobility.com/api/fleetrequestservice/fleetrequestservices',
    AddFleetRequestServiceUrl: 'https://api.garagemobility.com/api/fleetrequestservice/add',
    ViewFleetRequestServiceUrl: 'https://api.garagemobility.com/api/fleetrequestservice/show/',
    UpdateFleetRequestServiceUrl: 'https://api.garagemobility.com/api/fleetrequestservice/update',
    ActiveFleetServiceUrl: 'https://api.garagemobility.com/api/fleetservice/fleetserviceactive',
    FleetDashboardSummary: 'https://api.garagemobility.com/api/dashboardsummary/fleetdashboardsummarytoday',
    FleetDashboardDateRangeSummary: 'https://api.garagemobility.com/api/dashboardsummary/fleetdaterangedashboardsummary',
    FleetDriverOrderPartsUrl: 'https://api.garagemobility.com/api/fleetorderparts/fleetdriverorderparts',
    FleetAddDriverOrderPartsUrl: 'https://api.garagemobility.com/api/fleetorderparts/createfleetdriverorder',
    FleetEmailOrder: 'https://api.garagemobility.com/api/fleetemail/fleetemailactive',
    AddFleetEmailOrder: 'https://api.garagemobility.com/api/fleetemail/addfleetemail',
    ViewFleetEmailOrder: 'https://api.garagemobility.com/api/fleetemail/show/',
    UpdateFleetEmailOrder: 'https://api.garagemobility.com/api/fleetemail/update',
    RemoveFleetEmailOrder: 'https://api.garagemobility.com/api/fleetemail/remove',
    FleetAccountUrl: 'https://api.garagemobility.com/api/user/fleetaccount',
    FleetCreateAccountUrl: 'https://api.garagemobility.com/api/user/createfleetaccount',
    CountryUrl: 'https://api.garagemobility.com/api/country/country',
    AddCountryUrl: 'https://api.garagemobility.com/api/country/add',
    ViewCountryUrl: 'https://api.garagemobility.com/api/country/show/',
    UpdateCountryUrl: 'https://api.garagemobility.com/api/country/update',
    RemoveCountryUrl: 'https://api.garagemobility.com/api/country/remove',
    ActiveCountryUrl: 'https://api.garagemobility.com/api/country/activecountry',
    ServiceStatusUrl: 'https://api.garagemobility.com/api/servicestatus/servicestatus',
    AddServiceStatusUrl: 'https://api.garagemobility.com/api/servicestatus/add',
    EditServiceStatusUrl: 'https://api.garagemobility.com/api/servicestatus/show/',
    UpdateServiceStatusUrl: 'https://api.garagemobility.com/api/servicestatus/update',
    RemoveServiceStatusUrl: 'https://api.garagemobility.com/api/servicestatus/remove',
    FleetDriverRequestServiceUrl: 'https://api.garagemobility.com/api/fleetrequestservice/fleetdriverrequestservice',
    FleetDriverAddRequestServiceUrl: 'https://api.garagemobility.com/api/fleetrequestservice/addfleetdriverrequestservice',
    FilterRetailStageUrl: 'https://api.garagemobility.com/api/channelpartner/filterretailstage',
    FilterRetailSalesPersonUrl: 'https://api.garagemobility.com/api/channelpartner/filterretailsalesperson',
    FilterRetailsDateRangeUrl: 'https://api.garagemobility.com/api/channelpartner/filterretaildaterange',
    FilterMechanicStageUrl: 'https://api.garagemobility.com/api/channelpartner/filtermechanicstage/',
    FilterFleetStageUrl: 'https://api.garagemobility.com/api/channelpartner/filterfleetstage/',
    FilterFleetStageIndividualUrl: 'https://api.garagemobility.com/api/channelpartner/filterfleetstageindividual/',
    MainDashboardSummaryUrl: 'https://api.garagemobility.com/api/dashboardsummary/maindashboardsummary',
    GarageModelUrl: 'https://api.garagemobility.com/api/garagemodel/garagemodel',
    AddGarageModelUrl: 'https://api.garagemobility.com/api/garagemodel/add',
    ViewGarageModelUrl: 'https://api.garagemobility.com/api/garagemodel/show/',
    UpdateGarageModelUrl: 'https://api.garagemobility.com/api/garagemodel/update',
    RemoveGarageModelUrl: 'https://api.garagemobility.com/api/garagemodel/remove',
    AllSalesUserUrl: 'https://api.garagemobility.com/api/user/salesusers',
    AllDistributorUserUrl: 'https://api.garagemobility.com/api/user/distributorusers',
    SalesPartnerUrl: 'https://api.garagemobility.com/api/channelpartner/salesuserchannelpartner',
    MainSalesPartnerUrl: 'https://api.garagemobility.com/api/channelpartner/mainsalesuserchannelpartner',
    AllTodaysMatrixUrl: 'https://api.garagemobility.com/api/dashboardsummary/mainmatrixdaily',
    AllDateRangeMasterDashboardUrl: 'https://api.garagemobility.com/api/dashboardsummary/datesrangemasterdashboardfilter',
    AllInventoryUrl: 'https://api.garagemobility.com/api/inventory/inventorys',
    AllDistributorsInventoryOrdersUrl: 'https://api.garagemobility.com/api/inventory/distributorordersinventory',
    AllDistributoryInventoryUrl: 'https://api.garagemobility.com/api/inventory/distributorinventory',
    AddInventoryUrl: 'https://api.garagemobility.com/api/inventory/add',
    AddDistributorInventoryUrl: 'https://api.garagemobility.com/api/inventory/adddistributorinventory',
    ViewInventoryUrl: 'https://api.garagemobility.com/api/inventory/show/',
    UpdateInventoryUrl: 'https://api.garagemobility.com/api/inventory/update',
    InventoryMoveUrl: 'https://api.garagemobility.com/api/inventory/inventorymove/',
    StockAdjustmentUrl: 'https://api.garagemobility.com/api/inventory/stockadjustment',
    StockInventoryAdjustmentUrl: 'https://api.garagemobility.com/api/inventory/stockdistributoradjustment',
    RemoveInventoryUrl: 'https://api.garagemobility.com/api/inventory/remove',
    LeadEmailUrl: 'https://api.garagemobility.com/api/leademail/leademail',
    AddLeadEmailUrl: 'https://api.garagemobility.com/api/leademail/add',
    UpdateLeadEmailUrl: 'https://api.garagemobility.com/api/leademail/update',
    ViewLeadEmailUrl: 'https://api.garagemobility.com/api/leademail/show/',
    DeleteLeadEmailUrl: 'https://api.garagemobility.com/api/leademail/remove',
    FulOrderedDeliveredUrl: 'https://api.garagemobility.com/api/order/orderfuldelivered',
    FulOrderedProcessingUrl: 'https://api.garagemobility.com/api/order/orderfulprocessing',
    FulOrderedYesNoUrl: 'https://api.garagemobility.com/api/order/orderfulyesno',
    RetrieveDriversOrdersUrl: 'https://api.garagemobility.com/api/order/retrievedriverorders',
    FulOrderedDispatchedUrl: 'https://api.garagemobility.com/api/order/orderfuldispatched',
    FulOrderedReceivedStatusUrl: 'https://api.garagemobility.com/api/order/orderfulreceived',
    UpdateFulOrderDataUrl: 'https://api.garagemobility.com/api/order/fulorderupdatedata',
    UpdateDeliveryNoteUrl: 'https://api.garagemobility.com/api/order/deliverynoteimageupload',
    UpdateDriverImageNoteUrl: 'https://api.garagemobility.com/api/order/driverimagenoteimageupload',
    UpdateDispatchDataUrl: 'https://api.garagemobility.com/api/order/dispatchimageupload',
    UpdatePackSortDataUrl: 'https://api.garagemobility.com/api/order/packsortimageupload',
    FulOrderProcDateurl: 'https://api.garagemobility.com/api/order/orderfulprocessingdate',
    FulOrderDeliveredDateurl: 'https://api.garagemobility.com/api/order/orderfuldelivereddate',
    FulOrderDispatchedDateurl: 'https://api.garagemobility.com/api/order/orderfuldispatcheddate',
    FulOrderReceivedDateurl: 'https://api.garagemobility.com/api/order/orderfulreceiveddate',
    FulOrderYesNoDateurl: 'https://api.garagemobility.com/api/order/orderfulyesnodate',
    UploadCollectionImageUrl: 'https://api.garagemobility.com/api/order/uploadcollectionimage',
    AddCollectionDataUrl: 'https://api.garagemobility.com/api/order/addcollection',
    AddOrderIssuesDataUrl: 'https://api.garagemobility.com/api/order/addorderissues',
    RetrievallRetailerReceiptUrl: 'https://api.garagemobility.com/api/order/createpdfallreceipt',
    RetailerDashboardSummaryUrl: 'https://api.garagemobility.com/api/retailersummary/retailerdashboardfilters',
    AllCollectionEmail: 'https://api.garagemobility.com/api/collectionemail/collectionemail',
    AddCollectionEmailUrl: 'https://api.garagemobility.com/api/collectionemail/add',
    DeleteCollectionEmailUrl: 'https://api.garagemobility.com/api/collectionemail/remove',
    ViewCollectionEmailUrl: 'https://api.garagemobility.com/api/collectionemail/show/',
    UpdateCollectionEmail: 'https://api.garagemobility.com/api/collectionemail/update',
    PartSoldForDistributorUrl: 'https://api.garagemobility.com/api/order/distributororderparts',
    DistributorFilterSummaryUrl: 'https://api.garagemobility.com/api/retailersummary/dashboarddistributorsfilters',
    CollectionDataUrl: 'https://api.garagemobility.com/api/order/collectionanddeposite',
    OrderIssueDataUrl: 'https://api.garagemobility.com/api/order/orderissuesdata',
    RetrieveDriverRidersUrl: 'https://api.garagemobility.com/api/order/retrievedrivers',
    ResolvedOrderIssuesUrl: 'https://api.garagemobility.com/api/order/resolvedorderissuesdata',
    CollectionDataUrlNG: 'https://api.garagemobility.com/api/order/collectionng',
    FilteredCollectionDataUrl: 'https://api.garagemobility.com/api/order/filtercollectionanddeposite',
    FilterOrderIssueDataUrl: 'https://api.garagemobility.com/api/order/filterorderissue',
    FilteredCollectionDataUrlNG: 'https://api.garagemobility.com/api/order/filtercollectionngs',
    DepositedDataUrls: 'https://api.garagemobility.com/api/order/depositeddatainfo',
    DepositedDataUrlsNG: 'https://api.garagemobility.com/api/order/depositeddatainfong',
    FilteredDepositedDataUrls: 'https://api.garagemobility.com/api/order/filterdepositeddatainfo',
    FilteredDepositedDataUrlsNG: 'https://api.garagemobility.com/api/order/filterdepositeddatainfong',
    CustomerComplaintUrls: 'https://api.garagemobility.com/api/customercomplaint/customercomplaint',
    AddCustomerComplaintUrls: 'https://api.garagemobility.com/api/customercomplaint/add',
    ActiveCustomerComplaintUrls: 'https://api.garagemobility.com/api/customercomplaint/customercomplaintactive',
    ViewCustomerComplaintUrls: 'https://api.garagemobility.com/api/customercomplaint/show/',
    UpdateCustomerComplaintUrls: 'https://api.garagemobility.com/api/customercomplaint/update',
    DeleteCustomerComplaintUrls: 'https://api.garagemobility.com/api/customercomplaint/remove',
    IssueDiagnosedUrls: 'https://api.garagemobility.com/api/issuediagnosed/issuediagnosed',
    AddIssueDiagnosedUrls: 'https://api.garagemobility.com/api/issuediagnosed/add',
    ViewIssuedDiagnosedUrls: 'https://api.garagemobility.com/api/issuediagnosed/show/',
    UpdateIssueDiagnosedUrls: 'https://api.garagemobility.com/api/issuediagnosed/update',
    DeleteIssueDiagnosedUrls: 'https://api.garagemobility.com/api/issuediagnosed/remove',
    AllJobCardUrls: 'https://api.garagemobility.com/api/jobcard/jobcard',
    DeleteJobCardUrls: 'https://api.garagemobility.com/api/jobcard/remove',
    AddJobCardsUrl: 'https://api.garagemobility.com/api/jobcard/add',
    AddJobCardCssUrl: 'https://api.garagemobility.com/api/jobcard/addjobcard',
    ViewJobCardsUrl: 'https://api.garagemobility.com/api/jobcard/show/',
    UpdateJobCardsUrl: 'https://api.garagemobility.com/api/jobcard/update',
    DownloadPdfJobCardsUrl: 'https://api.garagemobility.com/api/jobcard/createpdfjobcard',
    FilterJobCardByDateUrl: 'https://api.garagemobility.com/api/jobcard/filterjobcardbydate',
    AllProductsUrlSold: 'https://api.garagemobility.com/api/reports/orderdespositapproved',
    AllReceiptExcel: 'https://api.garagemobility.com/api/order/allreceipts',
    AutoMechanicUserUrl: 'https://api.garagemobility.com/api/jobcard/autoaccountusers',
    AutoMechanicCreateAccountUrl: 'https://api.garagemobility.com/api/jobcard/createautomechanicaccount',
    FleetVehicleRequestUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/fleetvehiclerequest',
    AddFleetVehicleRequestUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/add',
    ViewFleetVehicleRequestUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/show/',
    UpdateFleetVehicleRequestUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/update',
    UpdateFleetVehicleCompanyRequestUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/updatecompany',
    DeleteFleetVehicleRequestUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/remove',
    FilterFleetVehicleRequestUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/filterfleetrequestbydate',
    ApproveFleetVehicleRequestListUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/approvevehiclerequest',
    ApproveFleetVehicleRequestFilterByDateUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/filterapprovalvehiclerequestbydate',
    FilterFleetVehicleStatusUrl: 'https://api.garagemobility.com/api/fleetvehiclerequest/filterapprovalrequestbystatus',
    AddCompanyRequestServicingFleetUrl: 'https://api.garagemobility.com/api/requestservicingfleet/add',
    AllCompanyRequestServicingFleetUrl: 'https://api.garagemobility.com/api/requestservicingfleet/requestservicingfleet',
    AllAdminRequestServicingFleetUrl: 'https://api.garagemobility.com/api/requestservicingfleet/allrequestservicingcss',
    FilterCompanyRequestServicingDateFleetUrl: 'https://api.garagemobility.com/api/requestservicingfleet/filterrequestservicingbydatefleet',
    FilterAdminRequestServicingDateFleetUrl: 'https://api.garagemobility.com/api/requestservicingfleet/filterallrequestservicingfleetcss',
    FilterRequestServicingStatusUrl: 'https://api.garagemobility.com/api/requestservicingfleet/filterequestservicestatusdata',
    ShowUrlsRequestServicingFleets: 'https://api.garagemobility.com/api/requestservicingfleet/show/',
    UpdateUrlsRequestServicingFleets: 'https://api.garagemobility.com/api/requestservicingfleet/update',
    UpdateUrlsRequestServicingCompanyFleets: 'https://api.garagemobility.com/api/requestservicingfleet/updatecompany',
    DeleteUrlsRequestServicingFleets: 'https://api.garagemobility.com/api/requestservicingfleet/remove',
    PartFleetAllUrl: 'https://api.garagemobility.com/api/partfleet/partsfleet',
    AddPartFleetUrl: 'https://api.garagemobility.com/api/partfleet/add',
    ShowPartFleetUrl: 'https://api.garagemobility.com/api/partfleet/show/',
    UpdatePartFleetUrl: 'https://api.garagemobility.com/api/partfleet/update',
    RemovePartFleetUrl: 'https://api.garagemobility.com/api/partfleet/remove',
    OrderedPartCatalogAddUrl: 'https://api.garagemobility.com/api/partorderedcatalog/add',
    PartsCatalogUrlFleet: 'https://api.garagemobility.com/api/partorderedcatalog/partsorderedcatalog',
    PartsCatalogOrdersUrlFleet: 'https://api.garagemobility.com/api/partorderedcatalog/partcatalogorders',
    PartsOrdersShowUrlFleet: 'https://api.garagemobility.com/api/partorderedcatalog/show/',
    PartsOrdersUpdateUrlFleet: 'https://api.garagemobility.com/api/partorderedcatalog/update',
    PartsOrdersFleetAllUrls: 'https://api.garagemobility.com/api/partorderedcatalog/fleetpartordersall',
    ReportTodaysUrls: 'https://api.garagemobility.com/api/reports/todaytotalordersreporting',
    ReportingDateRangeUrl: 'https://api.garagemobility.com/api/reports/orderdaterangereporting',
    CollectionReportFinanceTodayUrl: 'https://api.garagemobility.com/api/reports/collectionreporttodayfinance',
    CollectionSalesReportTodayUrl: 'https://api.garagemobility.com/api/reports/collectionreporttodaysales',
    CollectionReportFinanceDateRangeUrl: 'https://api.garagemobility.com/api/reports/collectionreportdaterangefinance',
    CollectionSalesReportDateRangeUrl: 'https://api.garagemobility.com/api/reports/collectionreportdaterangesales',
    DepositSalesReportTodayUrl: 'https://api.garagemobility.com/api/reports/depositreporttodaysales',
    DepositSalesReportDateRangeUrl: 'https://api.garagemobility.com/api/reports/depositreportdaterangesales',
    DepositReportTodayFinanceUrl: 'https://api.garagemobility.com/api/reports/approveddepositreporttodayfinance',
    DepositReportDateRangeFinanceUrl: 'https://api.garagemobility.com/api/reports/approveddepositreportdaterangefinance',
    FleetManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/requestservicereminder',
    AddFleetManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/add',
    EditFleetManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/show/',
    UpdateFleetManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/updatecompanyreminder',
    DeleteFleetManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/remove',
    FilterFleetManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/filterrequestsbydatereminder',
    AllFleetMangerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/allrequestsremindercss',
    AllFilterFleetManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/filterallrequestsfleetcssreminder',
    AddFleetGarageManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/addgaragereminder',
    AllGarageManagerReminderUrls: 'https://api.garagemobility.com/api/requestservicereminder/allgarageremindercss',
    FilterGarageManagerReminderUrl: 'https://api.garagemobility.com/api/requestservicereminder/filtergaragereminderbydatereminder',
    AllServiceReminderVehicleProfileUrl: 'https://api.garagemobility.com/api/requestservicereminder/allserviceremindervehicle/',
    AllProductsPartsUrl: 'https://api.garagemobility.com/api/reports/orderdespositapproved',
    AllTargetListUrl: 'https://api.garagemobility.com/api/target/targetlists',
    AddTargetUrl: 'https://api.garagemobility.com/api/target/add',
    ViewTargetUrl: 'https://api.garagemobility.com/api/target/show/',
    UpdateTargetUrl: 'https://api.garagemobility.com/api/target/update',
    DeleteTargetUrl: 'https://api.garagemobility.com/api/target/remove',
    AllSalesPersonTargetUrl: 'https://api.garagemobility.com/api/target/allsalesassociate',
    AllTodoListUrl: 'https://api.garagemobility.com/api/todolist/todolists',
    AddTodoListUrl: 'https://api.garagemobility.com/api/todolist/add',
    ViewTodListUrl: 'https://api.garagemobility.com/api/todolist/show/',
    UpdateTodListUrl: 'https://api.garagemobility.com/api/todolist/update',
    DeleteTodListUrl: 'https://api.garagemobility.com/api/todolist/remove',
    GroupRetailerUrl: 'https://api.garagemobility.com/api/reports/partsoldaggregate',
    AllBatchStatusUrl: 'https://api.garagemobility.com/api/batchstatus/batchstatus',
    AddBatchStatusUrl: 'https://api.garagemobility.com/api/batchstatus/add',
    ViewBatchStatusUrl: 'https://api.garagemobility.com/api/batchstatus/show/',
    UpdateBatchStatusUrl: 'https://api.garagemobility.com/api/batchstatus/update',
    DeleteBatchStatusUrl: 'https://api.garagemobility.com/api/batchstatus/remove',
    RetailersOrdersTodayUrl: 'https://api.garagemobility.com/api/order/groupretailersorderstoday',
    RetailerDebitTrackingUrl: 'https://api.garagemobility.com/api/order/retailerdebittracking',
    FilterRetailersOrdersDateUrl: 'https://api.garagemobility.com/api/order/filterretailerordersdate',
    RetailerPdfReceiptUrl: 'https://api.garagemobility.com/api/order/createpdfretailers',
    AllSalesPersonOrdersUrl: 'https://api.garagemobility.com/api/order/groupsalespersonorderstoday',
    AllSalesPersonFilterOrdersUrl: 'https://api.garagemobility.com/api/order/filtersalespersonordersdate',
    AllPartsSoldUrl: 'https://api.garagemobility.com/api/order/partsoldtoday',
    AllFilterPartsSoldUrl: 'https://api.garagemobility.com/api/order/filterpartssolddate',
    AllDeliveryNoteImageUrl: 'https://api.garagemobility.com/api/order/todaydeliverynoteorder',
    AllDispatchImageUrl: 'https://api.garagemobility.com/api/order/todaydispatchorder',
    AllPackSortImageUrl: 'https://api.garagemobility.com/api/order/todaypacksortorder',
    AllDriverDeliveryNoteImageUrl: 'https://api.garagemobility.com/api/order/todaydriverdeliverynoteorder',
    FilterDriverDeliveryNoteImageUrl: 'https://api.garagemobility.com/api/order/filterdriverdeliverynoteorder',
    FilterDeliveryNoteImageUrl: 'https://api.garagemobility.com/api/order/filterdeliverynoteorder',
    FilterDispatchImageUrl: 'https://api.garagemobility.com/api/order/filterdispatchorder',
    FilterPackSortImageUrl: 'https://api.garagemobility.com/api/order/filterpacksortorder',
    AllPartListNamesUrl: 'https://api.garagemobility.com/api/partlistnames/partlistnames',
    AddPartListNamesUrl: 'https://api.garagemobility.com/api/partlistnames/add',
    ViewPartListNamesUrl: 'https://api.garagemobility.com/api/partlistnames/show/',
    UpdatePartListNamesUrl: 'https://api.garagemobility.com/api/partlistnames/update',
    DeletePartListNamesUrl: 'https://api.garagemobility.com/api/partlistnames/remove',
    AllPartZonesUrl: 'https://api.garagemobility.com/api/partzone/partzonelist',
    PartlistnewUrlInfo: 'https://api.garagemobility.com/api/partzone/partlistnewMain/',
    AddPartZonesUrl: 'https://api.garagemobility.com/api/partzone/add',
    ViewPartZonesUrl: 'https://api.garagemobility.com/api/partzone/show/',
    UpdatePartZonesUrl: 'https://api.garagemobility.com/api/partzone/update',
    RemovePartZonesUrl: 'https://api.garagemobility.com/api/partzone/remove',
    PartPriceListDataUrl: 'https://api.garagemobility.com/api/partzone/salespartspricelist',
    SalesMainPriceListUrl: 'https://api.garagemobility.com/api/partzone/mainsalespartspricelist',
    CSPartsPriceListDataUrl: 'https://api.garagemobility.com/api/partzone/cspartspricelist',
    MAINCSPartsPriceListDataUrl: 'https://api.garagemobility.com/api/partzone/maincspartspricelist',
    DeliveryNoteDataOrderUrl: 'https://api.garagemobility.com/api/deliveryautomation/vieworderdeliverynotedata/',
    StagingAreaUrl: 'https://api.garagemobility.com/api/stagingarea/stagingarea',
    AddStagingAreaUrl: 'https://api.garagemobility.com/api/stagingarea/add',
    ViewStagingAreaUrl: 'https://api.garagemobility.com/api/stagingarea/show/',
    UpdateStagingAreaUrl: 'https://api.garagemobility.com/api/stagingarea/update',
    RemoveStagingAreaUrl: 'https://api.garagemobility.com/api/stagingarea/remove',
    LoyaltyListUrl: 'https://api.garagemobility.com/api/loyalties/loyalty',
    AddloyaltyListUrl: 'https://api.garagemobility.com/api/loyalties/add',
    EditLoyaltyListUrl: 'https://api.garagemobility.com/api/loyalties/show/',
    UpdateLoyaltyListUrl: 'https://api.garagemobility.com/api/loyalties/update',
    DeleteLoyaltyListUrl: 'https://api.garagemobility.com/api/loyalties/remove',
    VatReceiptUrl: 'https://api.garagemobility.com/api/order/createvatreceipt',
    ReceiptVatAllUrl: 'https://api.garagemobility.com/api/order/createvatretailerpdf',
    AllMessagesUrl: 'https://api.garagemobility.com/api/sendmessages/allmessages',
    FilterMessagesUrl: 'https://api.garagemobility.com/api/sendmessages/filtermessagesbydate',
    SendSmsMessageUrl: 'https://api.garagemobility.com/api/sendmessages/sendsms',
    MessageGroupsUrl: 'https://api.garagemobility.com/api/sendmessages/allgroups',
    MessageAddGroupsUrl: 'https://api.garagemobility.com/api/sendmessages/addgroup',
    MessageViewGroupsUrl: 'https://api.garagemobility.com/api/sendmessages/showgroups/',
    MessageUpdateGroupUrl: 'https://api.garagemobility.com/api/sendmessages/updategroups',
    SelectedGroupsUrl: 'https://api.garagemobility.com/api/sendmessages/selectedgroups',
    checkLocatioindataTesturl: 'https://api.garagemobility.com/api/channelpartner/checklocation',
    TagUrl: 'https://api.garagemobility.com/api/tag/tags',
    RemoveTagUrl: 'https://api.garagemobility.com/api/tag/remove',
    AddTagUrl: 'https://api.garagemobility.com/api/tag/add',
    ViewTagUrl: 'https://api.garagemobility.com/api/tag/show/',
    UpdateTagUrl: 'https://api.garagemobility.com/api/tag/update',
    TagFilterUrl: 'https://api.garagemobility.com/api/tag/tagsfilter/',
    AttributeUrl: 'https://api.garagemobility.com/api/attribute/attribute',
    RemoveAttributeUrl: 'https://api.garagemobility.com/api/attribute/remove',
    AddAttributeUrl: 'https://api.garagemobility.com/api/attribute/add',
    ViewAttributeUrl: 'https://api.garagemobility.com/api/attribute/show/',
    UpdateAttributeUrl: 'https://api.garagemobility.com/api/attribute/update',
    CityUrl: 'https://api.garagemobility.com/api/city/city',
    AddCityUrl: 'https://api.garagemobility.com/api/city/add',
    ViewCityUrl: 'https://api.garagemobility.com/api/city/show/',
    UpdateCityUrl: 'https://api.garagemobility.com/api/city/update',
    RemoveCityUrl: 'https://api.garagemobility.com/api/city/remove',
    CityFilterUrl: 'https://api.garagemobility.com/api/city/cityfilter/',
    CityByCountryUrl: 'https://api.garagemobility.com/api/city/citybycountry',
    AddDarkStoreAreaUrl: 'https://api.garagemobility.com/api/darkstorearea/add',
    AllDarkStoreAreaUrl: 'https://api.garagemobility.com/api/darkstorearea/darkstorearea',
    ViewDarkStoreAreaUrl: 'https://api.garagemobility.com/api/darkstorearea/show/',
    UpdateDarkStoreAreaUrl: 'https://api.garagemobility.com/api/darkstorearea/update',
    RemoveDarkStoreAreaUrl: 'https://api.garagemobility.com/api/darkstorearea/remove',
    PartsSoldAllByRMsUrl: 'https://api.garagemobility.com/api/reports/grouppartssoldbyrm',
    FilterPartsSoldAllByRMsUrl: 'https://api.garagemobility.com/api/reports/filtergrouppartssoldbyrm',
    TestPriceUrls: 'https://api.garagemobility.com/api/salesplanner/groupordersretailer',
    AllOrdersDataParts: 'https://api.garagemobility.com/api/channelpartner/onboardedretailers',
    FilterAllOrdersDataParts: 'https://api.garagemobility.com/api/reports/filterorderpartsdata',
    AuditLogOrderUrl: 'https://api.garagemobility.com/api/order/showauditlog/',
    RetailerAuditLogUrl: 'https://api.garagemobility.com/api/channelpartner/showcustomerlogs/',
    BatchAutomationUrl: 'https://api.garagemobility.com/api/batchautomation/batchautomation',
    AddBatchAutomationUrl: 'https://api.garagemobility.com/api/batchautomation/add',
    ViewBatchAutomationUrl: 'https://api.garagemobility.com/api/batchautomation/show/',
    RemoveBatchAutomationUrl: 'https://api.garagemobility.com/api/batchautomation/remove',
    UpdateBatchAutomationUrl: 'https://api.garagemobility.com/api/batchautomation/update',
    PriorityAutomationUrl: 'https://api.garagemobility.com/api/priorityautomation/priorityautomation',
    AddPriorityAutomationUrl: 'https://api.garagemobility.com/api/priorityautomation/add',
    EditPriorityAutomationUrl: 'https://api.garagemobility.com/api/priorityautomation/show/',
    UpdatePriorityAutomationUrl: 'https://api.garagemobility.com/api/priorityautomation/update',
    RemovePriorityAutomationUrl: 'https://api.garagemobility.com/api/priorityautomation/remove',
    SalesPodUrl: 'https://api.garagemobility.com/api/salesdrivepod/salesdrivepod',
    PodUrlsMain: 'https://api.garagemobility.com/api/salesdrivepod/drivepodsdata',
    RMCCPodUrl: 'https://api.garagemobility.com/api/salesdrivepod/rmccdata',
    AddSalesPodUrl: 'https://api.garagemobility.com/api/salesdrivepod/addpods',
    ViewSalesPodurl: 'https://api.garagemobility.com/api/salesdrivepod/show/',
    RemoveSalesPodUrl: 'https://api.garagemobility.com/api/salesdrivepod/remove',
    UpdateSalesPodUrl: 'https://api.garagemobility.com/api/salesdrivepod/update',
    WarehouseUrl: 'https://api.garagemobility.com/api/warehouse/warehouse',
    AddWareHouseUrl: 'https://api.garagemobility.com/api/warehouse/addwarehouse',
    ViewWareHouseUrl: 'https://api.garagemobility.com/api/warehouse/view/',
    UpdateWareHouseUrl: 'https://api.garagemobility.com/api/warehouse/update',
    RemoveWareHouseUrl: 'https://api.garagemobility.com/api/warehouse/remove',
    SalesDrivePodDataUrl: 'https://api.garagemobility.com/api/salesdrive/websalespod',
    AddSalesDriveBulkPodUrl: 'https://api.garagemobility.com/api/salesdrive/webaddsalesdrive',
    SalesDrivePodListUrl: 'https://api.garagemobility.com/api/salesdrive/websalesdriveorders',
    ShowSalesDrivePodUrl: 'https://api.garagemobility.com/api/salesdrive/showwebsalespod/',
    SalesDrivePodUpdateUrl: 'https://api.garagemobility.com/api/salesdrive/updatewebaddsalesdrive',
    PaymentNetworkUrl: 'https://api.garagemobility.com/api/order/momonetworks',
    AmountToPayUrl: 'https://api.garagemobility.com/api/order/amountcalculation/',
    OrderPayMoMoUrl: 'https://api.garagemobility.com/api/order/orderpay',
    CheckingOrderPayStatusUrl: 'https://api.garagemobility.com/api/order/orderpaymentstatus',
    MoMoCollectionsUrl: 'https://api.garagemobility.com/api/order/usermomocollection',
    BulkPayMoMoUrl: 'https://api.garagemobility.com/api/order/bulkorderpayment',
    CheckingBulkPayStatusUrl: 'https://api.garagemobility.com/api/order/bulkpaymentstatus',
    PreviewBulkPaymentUrl: 'https://api.garagemobility.com/api/order/previewmomocollection/',
    FilterMoMoCollectionsUrl: 'https://api.garagemobility.com/api/order/filtermomocollections',
    AllMoMoCollectionsUrl: 'https://api.garagemobility.com/api/order/allusermomocollection',
    AllFilterMoMoCollectionsUrl: 'https://api.garagemobility.com/api/order/allfiltermomocollections',
    CheckSmsBalanceUrl: 'https://api.garagemobility.com/api/sendmessages/checksmsbalance',
    PresoldSalesUrl: 'https://api.garagemobility.com/api/order/addpresoldorder',
    RmaPreSoldOrderUrl: 'https://api.garagemobility.com/api/order/rmapresolders',
    PodPresoldOrdersUrls: 'https://api.garagemobility.com/api/order/podpresolders'
};
export default ApiUrl;
